const dataFaq = [
  {
    id: 1,
    title: "Why should I register with Professional Placement Desk?",
    content:
      "Professional Placement Desk helps you find the right job matching your aspirations. ",
    show: "show",
  },
  {
    id: 2,
    title: "How does Profesional Placement Desk work?",
    content:
      "Profesional Placement Desk is a platform where recruiters and jobseekers can discover each other. Recruiters benefit from Profesional Placement Desk as they can advertise their jobs to millions of jobseekers. Jobseekers can discover over 2k jobs posted on Profesional Placement Desk and apply to them.",
  },
  {
    id: 3,
    title: "Does it cost to create a Professional Placement Desk profile?",
    content:
      "No, creating a profile on Professional Placement Desk is absolutely Free of Cost. Applying to jobs in Professional Placement Desk is also Free of Cost",
  },
  {
    id: 4,
    title: "I can’t remember the password for my account. How can I login again?",
    content:
      "If you have forgotten the password of your account, you need to reset it. An OTP will be sent to your registered email address, which can be used to set a new password",
  },
  {
    id: 5,
    title: "Browsing jobs on Professional Placement Desk ?",
    content:
      "If you don’t know exactly what type of job you are searching for, you can browse all the jobs posted on Professional Placement Desk.",
  },
  {
    id: 6,
    title: "How can I apply for a job on Professional Placement Desk?",
    content:
      "You can search for jobs that have been posted by companies and recruiters on Professional Placement Desk based on job title/designation or company names.",
  },
  // {
  //   id: 7,
  //   title: "Which of the Seven Wonders of the world do you ?",
  //   content:
  //     "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  // },
  // {
  //   id: 8,
  //   title: "What makes you happiest ?",
  //   content:
  //     "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  // },
];
export default dataFaq;
